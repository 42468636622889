.-translate-x-1\/2 {
  --tw-translate-x: -50% !important;
}
.bg-login-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url(assets/img/background-1920x1280.jpg);
}
@media (min-width: 1024px) {
  .w-100 {
    width: 37rem;
  }
  .embedModal {
    width:430px;
  }
  .renewModal {
    width:544px;
  }
}
@media (max-width: 1024px) {
  .h-screen {
    height: 150vh;
  }
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fcf;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.flexIt {
  display: flex;
  justify-content: space-between;
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.dollar_icon {
  font-size: 160px;
  color: #fff;
  background: goldenrod;
  border-radius: 80px;
}
.bg-adminlogin-background {
  background: rgb(0 0 0 / 70%);
}
.embeddTransY {
  --tw-translate-y: -30%
}
.h-screen.fixed.top-0.md\:left-0.-left-64.overflow-y-auto.flex-row.flex-nowrap.overflow-hidden.shadow-xl.bg-white.w-64.z-10.py-4.px-6.transition-all.duration-300 {
  display: block;
}
nav.bg-light-blue-500.md\:ml-64.py-6.px-3 {
  display: block;
}
.loginLinks {
  font-style: italic;
  color: #03a9f4;
}
.loginLinks:hover {
  text-decoration: underline;
}
.costCharged {
  width:20%
}